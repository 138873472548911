<template>
  <div class="classification">
    <div class="search-box ">
      <van-search v-model="key"
                  shape="round"
                  background="rgba(0,0,0,0)"
                  placeholder="搜索商品" />
      <!-- <div class="search flex ai-c">
              <img src="/img/icon-search.png" alt="">
              搜索商品
          </div> -->
    </div>
    <!-- <div class="number">
          123456人正在下单
      </div> -->
    <div class="box flex jc-c">
      <van-tabs v-model="active"
                sticky>
        <van-tab v-for="(item, index) in nav"
                 :name="item.goodsTagId"
                 :key="index"
                 :title="item.name">
          <van-list v-model="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad"
                    class="list flex fd-c ai-c">
            <div class="item flex ai-c"
                 @click="$router.push({path:'/home/details',query: {id: im.goodsId}})"
                 v-for="(im, ix) in list"
                 :key="ix">
              <div v-if="ix==0"
                   class="nb ranking1">{{ix+1}}</div>
              <div v-if="ix==1"
                   class="nb ranking2">{{ix+1}}</div>
              <div v-if="ix==2"
                   class="nb ranking3">{{ix+1}}</div>
              <div v-if="ix>=3"
                   class="nb ranking4">{{ix+1}}</div>
              <van-image class="goods-img" fit="cover" lazy-load :src="im.pic" />
              <!-- <img class="goods-img"
                   :src="im.pic"
                   alt=""> -->
              <div class="item-r">
                <div class="name lines">{{im.name}}</div>
                <div class="translate-box line">
                  {{im.keywords}}
                </div>
                <div class="lb">{{im.sale}}人已下单</div>
                <div class="price flex ai-c">
                  <div class="t1">立省{{im.save}}</div>
                  <div class="t2">¥{{im.priceLining}}</div>
                </div>
                <div class="btn-box flex ai-c">
                  <div class="t1">
                    <font>¥</font>{{im.price}}
                  </div>
                  <img class="t2"
                       src="/img/cart.png"
                       alt="">
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <v-tabbar :active="1" />
  </div>
</template>

<script>
import { goodsTag } from '../../api'
import tabbar from '@/components/tabbar'
export default {
  name: 'classification',
  components: {
    'v-tabbar': tabbar,
  },
  props: {},
  data() {
    return {
      nav: [
        {
          name: '热门美食',
          goodsTagId: 16
        },
        {
          name: '大牌直降',
          goodsTagId: 13
        },
        {
          name: '生活日用',
          goodsTagId: 14
        },
        {
          name: '品质生活',
          goodsTagId: 15
        },
      ],
      active: 3,
      list: [],
      loading: false,
      finished: true,
      current: 1,
      key: '',
      timer: null
    }
  },
  watch: {
    active(nval, oval) {
      if (nval != oval) {
        this.current = 1
        this.finished = true;
        this.list = []
        this.goodsTag()
      }
    },
    key() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.current = 1
        this.finished = true;
        this.list = []
        this.goodsTag()

      }, 1000);
    }
  },
  computed: {},
  methods: {
    navClick(item) {
      this.active = item.goodsTagId
      this.current = 1
      this.finished = true;
      this.list = []
      this.goodsTag()
    },

    onLoad() {
      // 异步更新数据
      this.goodsTag()

    },
    //新品推荐
    goodsTag() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true
      })
      goodsTag({
        key: this.key,
        goodsTagId: this.active,
        current: this.current,
        size: 20,
      }).then(res => {
        var list = res.data.data.records
        // 加载状态结束
        this.loading = false;
        this.current++
        if (list.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        for (var item of list) {
          item.pic = item.pic ? item.pic.split(',')[0] : []
          item.save = this.NumberSub(item.priceLining, item.price)
          this.list.push(item)
        }
      }).finally(() => {
        this.$toast.clear()
      })
    },
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>
<style scoped>
.classification {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 1.4rem;
  background-repeat: no-repeat;
  background-image: url(/img/classification-bg.png);
  background-position-y: -1.2rem;
  background-size: 100% auto;
  overflow: hidden;
  background-color: RGBA(238, 238, 238, 1);
}
.search-box {
  width: 100%;
}
.search-box .icon-itt {
  width: 0.56rem;
  height: 0.71rem;
  margin-left: 0.2rem;
}
.search-box .search {
  width: 7.1rem;
  height: 0.64rem;
  background: #ffffff;
  border-radius: 0.32rem;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bbbbbb;
  margin-left: 0.16rem;
}
.search-box .search img {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.2rem;
  margin-right: 0.15rem;
}
.number {
  width: 3.98rem;
  height: 0.38rem;
  box-sizing: border-box;
  padding-left: 0.2rem;
  line-height: 0.38rem;
  background-image: url(/img/number-bg.png);
  background-size: 100% 100%;
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #fffaf5;
  margin-left: 0.2rem;
  margin-top: 2.5rem;
}
.box {
  position: relative;
  width: 100%;
  margin-top: 2.8rem;
}
.box >>> .van-tabs {
  width: 7.1rem;
}
.box >>> .van-tabs__wrap {
  height: 0.7rem;
  align-items: center;
}
.box >>> .van-tab {
  flex: none;
  padding: 0 0.24rem;
  line-height: 0.54rem;
  margin-right: 0.1rem;
}
.box >>> .van-tabs__nav--line {
  height: 0.54rem;
  padding: 0.07rem 0.1rem;
  border-radius: 0.35rem;
}
.box >>> .van-tab--active {
  background: linear-gradient(0deg, #e73a2c, #f6786f);
  border: 2px solid#FBCA0F;
  /* border-image: linear-gradient(0deg, #FBCA0F, #FFDEBC) 2 2; */
  box-shadow: 0rem 0rem 0.08rem 0.02rem rgba(220, 44, 35, 0.8);
  border-radius: 0.27rem;
  /* overflow: hidden; */
  color: #fff;
  font-size: 0.3rem;
}
.box >>> .van-tabs__line {
  width: 0;
}
.box >>> .van-sticky--fixed .van-tabs__nav--line {
  border-radius: 0;
}
.list {
  margin-top: 0.2rem;
}
.list .item {
  position: relative;
  width: 7.1rem;
  height: 3.6rem;
  background: #ffffff;
  border-radius: 0.14rem;
  margin-bottom: 0.25rem;
}
.list .item-r {
  width: 3.55rem;
  height: 100%;
  margin-left: 0.25rem;
}
.goods-img {
  width: 2.76rem;
  height: 2.76rem;
  border-radius: 0.1rem;
  margin-left: 0.25rem;
}
.name {
  font-size: 0.3rem;
  line-height: 0.38rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
}
.lb {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #6e6e6e;
  line-height: 0.26rem;
  margin-top: 0.1rem;
}
.starList {
  margin-top: 0.05rem;
}
.starList >>> .van-icon-star-o {
  color: rgba(249, 111, 48, 1);
  line-height: 0.3rem;
}
.starList >>> .van-icon-star {
  color: rgba(249, 111, 48, 1);
  line-height: 0.3rem;
}
.price {
  margin-top: 0.2rem;
}
.price .t1 {
  font-size: 0.3rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #f55240;
}
.price .t2 {
  font-size: 0.24rem;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #6e6e6e;
  margin-left: 0.17rem;
  text-decoration: line-through;
}
.btn-box {
  margin-top: 0.05rem;
}

.btn-box .t1 {
  width: 2.36rem;
  height: 0.47rem;
  line-height: 0.47rem;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  background-image: url(/img/classification-goods-btn.png);
  background-size: 100% 100%;
  font-size: 0.3rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: #f55645;
  box-sizing: border-box;
  padding-left: 1.3rem;
}
.btn-box .t2 {
  width: 0.56rem;
  height: 0.56rem;
  margin-left: 0.65rem;
}
.btn-box .t1 font {
  font-size: 0.24rem;
}
.nb {
  position: absolute;
  left: 0.23rem;
  top: 0.22rem;
  width: 0.5rem;
  height: 0.74rem;
  text-align: center;
  line-height: 0.8rem;
  background-size: 100% 100%;
  font-size: 0.33rem;
  font-family: PingFang;
  font-weight: 800;
  color: #ffffff;
  z-index: 10;
  text-shadow: 0rem 0.04rem 0.13rem rgba(168, 110, 85, 0.41);
}
.ranking1 {
  background-image: url(/img/icon-ranking1.png);
}
.ranking2 {
  background-image: url(/img/icon-ranking2.png);
}
.ranking3 {
  background-image: url(/img/icon-ranking3.png);
}
.ranking4 {
  background-image: url(/img/icon-ranking4.png);
}
.translate-box {
  width: 3.42rem;
  height: 0.4rem;
  background-size: 100% 100%;
  background-image: url(/img/translate-box.png);
  font-size: 0.24rem;
  line-height: 0.4rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ca9154;
  padding-left: 0.5rem;
  box-sizing: border-box;
}
</style>